// SignupPage.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {TextField, Button, Typography, Container, Alert} from '@mui/material';
import { signupUser } from '../apis/auth';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await dispatch(signupUser({ email, password, username }));
        if (signupUser.fulfilled.match(result)) {
            navigate('/');
        } else {
            setError('Signup failed. Please check your username and password.');
        }
    };

    return (
        <Container maxWidth="sm" style={{paddingTop: "100px"}}>
            <Typography variant="h4" gutterBottom>
                Signup
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    type="text"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && (
                    <Alert severity="error" style={{ marginTop: '10px' }}>
                        {error}
                    </Alert>
                )}
                <Button type="submit" variant="contained" color="primary">
                    Signup
                </Button>
            </form>
        </Container>
    );
};

export default SignupPage;
