import React, { useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFetchUsersQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation } from '../../apis/users';

// Define the validation schema using Yup
const userSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters'),
});

function AdminUsers() {
    const { data: users = [], refetch } = useFetchUsersQuery();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();

    const [open, setOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(userSchema),
        defaultValues: {
            username: '',
            email: '',
            password: '',
        },
    });

    const handleOpenDialog = (user = null) => {
        setEditingUser(user);
        if (user) {
            reset({ username: user.username, email: user.email, password: '' }); // Password is left empty
        } else {
            reset({ username: '', email: '', password: '' });
        }
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setEditingUser(null);
    };

    const onSubmit = async (data) => {
        try {
            if (editingUser) {
                await updateUser({ id: editingUser.id, ...data });
            } else {
                await createUser(data);
            }
            refetch();
            handleCloseDialog();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleDeleteUser = async (userId) => {
        try {
            await deleteUser(userId);
            refetch();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>
            <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
                Create New User
            </Button>
            <Box sx={{ marginTop: 3 }}>
                {users?.results?.map((user) => (
                    <Box
                        key={user.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 2,
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <Box>
                            <Typography variant="body1">{user.username}</Typography>
                            <Typography variant="body2">{user.email}</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => handleOpenDialog(user)} color="primary">
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteUser(user.id)} color="secondary">
                                <Delete />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>{editingUser ? 'Edit User' : 'Create New User'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            {...register('username')}
                            autoFocus
                            margin="dense"
                            label="Username"
                            type="text"
                            fullWidth
                            error={!!errors.username}
                            helperText={errors.username?.message}
                        />
                        <TextField
                            {...register('email')}
                            margin="dense"
                            label="Email Address"
                            type="email"
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                        <TextField
                            {...register('password')}
                            margin="dense"
                            label="Password"
                            type="password"
                            fullWidth
                            error={!!errors.password}
                            helperText={errors.password?.message}
                        />
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default AdminUsers;
