import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useFetchPromptsQuery, useCreatePromptMutation, useUpdatePromptContentMutation, useDeletePromptMutation } from '../apis/promptApiSlice';

const PromptsPage = () => {
    const { data: promptData, isLoading } = useFetchPromptsQuery({ page: 1, pageSize: 10 });
    const [createPrompt] = useCreatePromptMutation();
    const [updatePromptContent] = useUpdatePromptContentMutation();
    const [deletePrompt] = useDeletePromptMutation();
    const [newPrompt, setNewPrompt] = useState({ name: '', content: '', notes: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPrompt({ ...newPrompt, [name]: value });
    };

    const handleCreatePrompt = async () => {
        await createPrompt(newPrompt);
        setNewPrompt({ name: '', content: '', notes: '' });
    };

    const handleEditPrompt = async (id, content) => {
        await updatePromptContent({ id, content });
    };

    const handleDeletePrompt = async (id) => {
        await deletePrompt(id);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Prompts
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promptData?.data.map(prompt => (
                            <TableRow key={prompt.id}>
                                <TableCell>{prompt.name}</TableCell>
                                <TableCell>
                                    <TextField
                                        value={prompt.content}
                                        onChange={(e) => handleEditPrompt(prompt.id, e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>{prompt.notes}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDeletePrompt(prompt.id)} color="secondary">
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                Add New Prompt
            </Typography>
            <TextField
                label="Name"
                name="name"
                value={newPrompt.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Content"
                name="content"
                value={newPrompt.content}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Notes"
                name="notes"
                value={newPrompt.notes}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleCreatePrompt} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                Add Prompt
            </Button>
        </Container>
    );
};

export default PromptsPage;
