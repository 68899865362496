import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadChartMutation, useUploadTextMutation, useFetchChartsQuery } from "../apis/chart";
import { Typography, IconButton, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const UploadPage = () => {
    const [uploadChart] = useUploadChartMutation();
    const [uploadText] = useUploadTextMutation();
    const { refetch } = useFetchChartsQuery({ page: 1, pageSize: 10 });
    const [textContent, setTextContent] = useState("");
    const [collapsed, setCollapsed] = useState(false);

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            await uploadChart(formData).unwrap();
            refetch();
        } catch (error) {
            console.error('Error uploading chart:', error);
        }
    };

    const handleTextareaChange = (event) => {
        setTextContent(event.target.value);
    };

    const handleTextareaSubmit = async () => {
        const formData = new FormData();
        formData.append('content', textContent);

        try {
            await uploadText(formData).unwrap();
            refetch();
            setTextContent("");
        } catch (error) {
            console.error('Error uploading text:', error);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const toggleCollapse = () => {
        setCollapsed((prev) => !prev);
    };

    return (
        <div style={{ margin: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleCollapse}>
                <IconButton>
                    {collapsed ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }}>Chart Upload</Typography>
            </div>
            <Collapse in={!collapsed}>
                <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                    <div style={{ flex: 1 }}>
                        <textarea
                            value={textContent}
                            onChange={handleTextareaChange}
                            placeholder="Paste your text here"
                            style={{ width: '100%', height: '150px', padding: '10px', boxSizing: 'border-box', marginBottom: '20px' }}
                        />
                        <button onClick={handleTextareaSubmit} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>
                            Submit Text
                        </button>
                    </div>
                    <div {...getRootProps()} style={{ flex: 1, border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
                        <input {...getInputProps()} />
                        {isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop a file here, or click to select a file</p>
                        }
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default UploadPage;
