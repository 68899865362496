import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CodeCard from './CodeCard';
import theme from "../../styles/theme";
import ConditionHeader from './ConditionHeader';

const ConditionsList = ({ aiIdentifiedConditions, completedCodes, userSelectedCodes, handleMoveToCompleted, handleCodeClick, highlightedText }) => {
    const filteredAvailableCodes = aiIdentifiedConditions.flatMap(condition =>
        condition.codes.map(code => ({ ...code, condition: condition.condition.description }))
    ).concat(userSelectedCodes.map(code => ({ ...code, condition: 'User Added' })))
      .filter(code => !completedCodes.some(completedCode => completedCode.icd_code.code === code.icd_code.code));

    const groupedCodes = aiIdentifiedConditions.map(condition => {
        return {
            condition: condition.condition,
            codes: filteredAvailableCodes.filter(code => code.condition === condition.condition.description),
            highlight_text: condition.highlight_text,
            rationale: condition.rationale
        };
    }).filter(group => group.codes.length > 0);

    const userAddedCodes = filteredAvailableCodes.filter(code => code.condition === 'User Added');

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                {userAddedCodes.length > 0 && (
                    <Card key="User Added">
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                User Added
                            </Typography>
                            {userAddedCodes
                                .sort((a, b) => a.icd_code.code.localeCompare(b.icd_code.code))
                                .map(code => (
                                    <CodeCard
                                        key={code.code}
                                        code={code}
                                        action={handleMoveToCompleted}
                                        aiIdentifiedConditions={aiIdentifiedConditions}
                                        userSelectedCodes={userSelectedCodes}
                                    />
                                ))}
                        </CardContent>
                    </Card>
                )}
                {groupedCodes.map(group => (
                    <Card key={group.condition.description}>
                        <CardContent>
                             <ConditionHeader
                                group={group}
                                handleCodeClick={handleCodeClick}
                                highlightedText={highlightedText}
                                isExpanded={true} // Assuming we always want to show the codes
                                onToggleExpand={() => {}} // No-op since we're not collapsing
                            />
                            {group.codes
                                .sort((a, b) => a.icd_code.code.localeCompare(b.icd_code.code))
                                .map(code => (
                                    <CodeCard
                                        key={code.code}
                                        code={code}
                                        action={handleMoveToCompleted}
                                        aiIdentifiedConditions={aiIdentifiedConditions}
                                        userSelectedCodes={userSelectedCodes}
                                    />
                                ))}
                        </CardContent>
                    </Card>
                ))}

            </Box>
        </ThemeProvider>
    );
};

export default ConditionsList;
