import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const userApiSlice = createApi({
    reducerPath: 'userApi',
    baseQuery,
    endpoints: (builder) => ({
        fetchUsers: builder.query({
            query: () => 'users/',
        }),
        fetchUserById: builder.query({
            query: (userId) => `users/${userId}/`,
        }),
        createUser: builder.mutation({
            query: (newUser) => ({
                url: 'users/',
                method: 'POST',
                body: newUser,
            }),
        }),
        updateUser: builder.mutation({
            query: (updatedUser) => ({
                url: `users/${updatedUser.id}/`,
                method: 'PUT',
                body: updatedUser,
            }),
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `users/${userId}/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useFetchUsersQuery,
    useFetchUserByIdQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = userApiSlice;
