// /* CSS HEX */
/* CSS HEX */
// --cool-gray: #8b94a3ff;
// --aquamarine: #8cfbdeff;
// --spring-green: #0fff95ff;
// --emerald: #06ba63ff;
// --pakistan-green: #103900ff;
//
// /* CSS HSL */
// --cool-gray: hsla(217, 12%, 59%, 1);
// --aquamarine: hsla(164, 93%, 77%, 1);
// --spring-green: hsla(154, 100%, 53%, 1);
// --emerald: hsla(151, 94%, 38%, 1);
// --pakistan-green: hsla(103, 100%, 11%, 1);
//
// /* SCSS HEX */
// $cool-gray: #8b94a3ff;
// $aquamarine: #8cfbdeff;
// $spring-green: #0fff95ff;
// $emerald: #06ba63ff;
// $pakistan-green: #103900ff;
//
// /* SCSS HSL */
// $cool-gray: hsla(217, 12%, 59%, 1);
// $aquamarine: hsla(164, 93%, 77%, 1);
// $spring-green: hsla(154, 100%, 53%, 1);
// $emerald: hsla(151, 94%, 38%, 1);
// $pakistan-green: hsla(103, 100%, 11%, 1);
//
// /* SCSS RGB */
// $cool-gray: rgba(139, 148, 163, 1);
// $aquamarine: rgba(140, 251, 222, 1);
// $spring-green: rgba(15, 255, 149, 1);
// $emerald: rgba(6, 186, 99, 1);
// $pakistan-green: rgba(16, 57, 0, 1);
//
// /* SCSS Gradient */
// $gradient-top: linear-gradient(0deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-right: linear-gradient(90deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-bottom: linear-gradient(180deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-left: linear-gradient(270deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-top-right: linear-gradient(45deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-bottom-right: linear-gradient(135deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-top-left: linear-gradient(225deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-bottom-left: linear-gradient(315deg, #8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);
// $gradient-radial: radial-gradient(#8b94a3ff, #8cfbdeff, #0fff95ff, #06ba63ff, #103900ff);


// $cool-gray: #F6F6F6;
import { createTheme } from '@mui/material/styles';

// Define the custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#41658aff', // Lapis Lazuli
        },
        secondary: {
            main: '#70a37fff', // Cambridge Blue
        },
        error: {
            main: '#d32f2f', // Red (default error color)
        },
        background: {
            default: '#F6F6F6', // Mantis
            paper: '#ffffff', // White background for paper components
        },
        text: {
            primary: '#414073ff', // Delft Blue
            secondary: '#4c3957ff', // English Violet
        },
        warning: {
            main: '#ffbf46ff', // Xanthous for warning
        },
        success: {
            main: '#70a37fff', // Cambridge Blue for success
        },
        highlight: {
            main: '#FFFF00' // for the source highlighters
        },
        codes: {
            listed: {
                background: '#70a37fff',
                color: '#ffffff',
            },
            ai: {
                background: '#ffbf46ff',
                color: '#414073ff',
            },
            user: {
                background: '#ff7043',
                color: '#ffffff',
            },
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif', // Set the global font family
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(90deg, hsl(210, 36%, 40%) 0%, hsl(210, 36%, 50%) 100%)',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // White background for paper components
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                },
            },
        },
    },
});

export default theme;