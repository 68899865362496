import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { loginUser } from '../apis/auth';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous error
        const result = await dispatch(loginUser({ username, password }));
        if (loginUser.fulfilled.match(result)) {
            navigate('/');
        } else {
            setError('Login failed. Please check your username and password.');
        }
    };

    return (
        <Container maxWidth="sm" style={{ paddingTop: "100px" }}>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    type="text"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && (
                    <Alert severity="error" style={{ marginTop: '10px' }}>
                        {error}
                    </Alert>
                )}
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    Login
                </Button>
            </form>
        </Container>
    );
};

export default LoginPage;
