import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import HighlightIcon from '@mui/icons-material/Highlight';

const titleCase = (str) => {
    return str.replace(/\b\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const ConditionHeader = ({ group, handleCodeClick, highlightedText, isExpanded, onToggleExpand }) => {
    const cleanHighlightText = group.highlight_text.replace(/\.$/, ''); // Remove trailing period

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <IconButton
                onClick={() => handleCodeClick(cleanHighlightText)}
                color={highlightedText === cleanHighlightText ? 'primary' : 'default'}
            >
                <HighlightIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flex: 1 }}>
                {titleCase(group.condition.name)} ({group.codes.length})
            </Typography>
            {highlightedText === cleanHighlightText && (
                <Typography variant="body2" sx={{ color: 'primary.main', marginLeft: 2 }}>
                    Highlighted
                </Typography>
            )}
        </Box>
    );
};

export default ConditionHeader;
