import React from 'react';
import TabbedCharts from './TabbedCharts';

const ChartTable = () => {
    return (
        <div>
            <TabbedCharts />
        </div>
    );
};

export default ChartTable;
