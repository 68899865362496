import { logout } from './auth';

const authMiddleware = (store) => (next) => (action) => {
    if (action.type.endsWith('rejected') && action.payload?.status === 401) {
        store.dispatch(logout());
        window.location.href = '/login';
    }

    return next(action);
};

export default authMiddleware;
