import React from 'react';
import ChartTable from "./ChartTable";

const LandingPage = () => {

    return (
        <div className="home-container">
            <ChartTable />
        </div>
    );
};

export default LandingPage;
