import React from 'react';

const PDFViewer = ({ fileUrl }) => {
    return (
        <iframe
            src={fileUrl}
            title="PDF Viewer"
            width="100%"
            height="1000px"

            style={{ border: 'none' }}
        />
    );
};

export default PDFViewer;
