import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth';
import {chartApiSlice} from './chart';
import {promptApiSlice} from './promptApiSlice';
import {physiciansApiSlice} from './physicians';
import {patientApiSlice} from './patients';
import {userApiSlice} from './users';

import authMiddleware from './authMiddleware'; // Ensure the correct path

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [chartApiSlice.reducerPath]: chartApiSlice.reducer,
        [promptApiSlice.reducerPath]: promptApiSlice.reducer,
        [physiciansApiSlice.reducerPath]: physiciansApiSlice.reducer,
        [patientApiSlice.reducerPath]: patientApiSlice.reducer,
        [userApiSlice.reducerPath]: userApiSlice.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            chartApiSlice.middleware,
            promptApiSlice.middleware,
            physiciansApiSlice.middleware,
            patientApiSlice.middleware,
            userApiSlice.middleware,
            authMiddleware
        ),
});
