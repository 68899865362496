// apis/physicians.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const physiciansApiSlice = createApi({
    reducerPath: 'physiciansApi',
    baseQuery,
    endpoints: (builder) => ({
        fetchPhysicians: builder.query({
            query: () => 'physicians/',
        }),
        fetchCoderQueries: builder.query({
            query: (chartId) => `coder-queries/?chart=${chartId}`,
        }),
        createCoderQuery: builder.mutation({
            query: (query) => ({
                url: 'coder-queries/',
                method: 'POST',
                body: query,
            }),
        }),
        addMessage: builder.mutation({
            query: ({ queryId, content, sender_type }) => ({
                url: `coder-queries/${queryId}/messages/`,
                method: 'POST',
                body: { content, sender_type },
            }),
        }),
    }),
});

export const {
    useFetchPhysiciansQuery,
    useFetchCoderQueriesQuery,
    useCreateCoderQueryMutation,
    useAddMessageMutation
} = physiciansApiSlice;
