import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchChartsQuery, useDeleteChartMutation, useFetchChartCountsQuery } from '../apis/chart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button, Chip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// A generic component for displaying a DataGrid of charts
const ChartList = ({ status }) => {
   const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const { data, error, isLoading, isFetching, refetch } = useFetchChartsQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        status,
    });
    const [deleteChart] = useDeleteChartMutation();
    const navigate = useNavigate();
    // const [, setSelectedRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handlePaginationModelChange = useCallback((newModel) => {
        console.log('Pagination model change:', newModel);
        if (newModel.pageSize !== paginationModel.pageSize) {
            // If page size changed, reset to first page
            setPaginationModel({ ...newModel, page: 0 });
        } else {
            setPaginationModel(newModel);
        }
    }, [paginationModel.pageSize]);

    useEffect(() => {
        console.log('Effect triggered - Page:', paginationModel.page, 'PageSize:', paginationModel.pageSize);
        refetch();
    }, [paginationModel, refetch]);

    useEffect(() => {
        if (data) {
            console.log('Received data:', data);
            console.log('Number of rows:', data.data.length);
            console.log('Total count:', data.total);
        }
        if (error) {
            console.error('Error fetching data:', error);
        }
    }, [data, error]);

    const handleDelete = async () => {
        await deleteChart(selectedId);
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleOpenDetails = (id) => {
        navigate(`/chart/${id}`);
    };

    const extractCodes = (codesArray) => {
        if (!codesArray) return '';
        return codesArray.map(codeObj => codeObj.code).join(', ');
    };

    const extractConditions = (conditionsArray) => {
        if (!conditionsArray) return '';
        return conditionsArray.map(conditionObj => conditionObj.condition.name).join(', ');
    };

    const getChipProps = (chartType) => {
        switch (chartType) {
            case 'Type1':
                return { label: 'Type1', color: 'primary' };
            case 'Type2':
                return { label: 'Type2', color: 'secondary' };
            case 'Type3':
                return { label: 'Type3', color: 'error' };
            case 'Type4':
                return { label: 'Type4', color: 'warning' };
            case 'Type5':
                return { label: 'Type5', color: 'success' };
            default:
                return { label: chartType, color: 'default' };
        }
    };


    const columns = [
        {
            field: 'date_of_service',
            headerName: 'DOS',
            flex: 1,
            renderCell: (params) => dayjs(params.row.date_of_service).format('MM-DD-YYYY'),
        },
        {
            field: 'chart_source',
            headerName: 'Source',
            flex: 1,
        },
        {
            field: 'patient_id',
            headerName: 'Patient ID',
            flex: 1,
        },
        {
            field: 'chart_type',
            headerName: 'Chart Type',
            flex: 2,
            renderCell: (params) => {
                const chipProps = getChipProps(params.value);
                return <Chip label={chipProps.label} color={chipProps.color} />;
            },
        },
        {
            field: 'conditions',
            headerName: 'Identified Conditions',
            flex: 2,
        },
        {
            field: 'open',
            headerName: 'Open',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <IconButton onClick={() => handleOpenDetails(params.id)}>
                    <OpenInNewIcon />
                </IconButton>
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <IconButton onClick={() => {
                    setSelectedId(params.id);
                    setOpen(true);
                }}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

  const rows = data?.data?.map((chart) => ({
        id: chart.uuid,
        date_of_service: chart.date_of_service,
        chart_source: chart.source,
        patient_id: chart.patient?.uuid || 'Unknown',
        chart_type: chart.chart_type,
        conditions: extractConditions(chart.coding_results?.ai_identified_conditions || []),
        codes_confirmed: chart.coding_results?.confirmed_codes ? extractCodes(chart.coding_results?.confirmed_codes || []) : '',
        is_processed: chart.is_processed,
        coded_by: chart.coded_by?.username || 'Unknown',
        documentation_deficiency: chart.documentation_deficiency || false
    })) || [];

    if (isLoading || isFetching) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;


    return (
        <>
            <DataGrid
                pagination
                rows={rows}
                columns={columns}
                // pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                paginationMode="server"
                rowCount={data?.total || 0}
                // page={page}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[25, 50, 100]}
            />

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this chart? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const TabbedCharts = () => {
    const [tabValue, setTabValue] = useState(0);
    const { data: chartCounts, isLoading, error } = useFetchChartCountsQuery();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (isLoading) return <div>Loading counts...</div>;
    if (error) return <div>Error loading counts: {error.message}</div>;

    const { newCount, completedCount, blockedCount, deficientCount } = chartCounts || { newCount: 0, completedCount: 0, blockedCount: 0, deficientCount: 0 };

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={`New (${newCount})`} />
                <Tab label={`Completed (${completedCount})`} />
                <Tab label={`Blocked (${blockedCount})`} />
                <Tab label={`Documentation Deficient (${deficientCount})`} />
            </Tabs>
            {tabValue === 0 && <ChartList status="new" />}
            {tabValue === 1 && <ChartList status="completed" />}
            {tabValue === 2 && <ChartList status="blocked" />}
            {tabValue === 3 && <ChartList status="documentation_deficient" />}
        </Box>
    );
};

export default TabbedCharts;
