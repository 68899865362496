import React, { useState } from 'react';
import { Modal, Box, Typography, Slider, Button } from '@mui/material';

const ProcessingTimeModal = ({ open, onClose, onSubmit }) => {
    const [processingTime, setProcessingTime] = useState(0);

    const handleSliderChange = (event, newValue) => {
        setProcessingTime(newValue);
    };

    const handleSubmit = () => {
        onSubmit(processingTime);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="processing-time-modal-title"
            aria-describedby="processing-time-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="processing-time-modal-title" variant="h6" component="h2">
                    Processing Time
                </Typography>
                <Typography id="processing-time-modal-description" sx={{ mt: 2 }}>
                    Select the time it took to process (in minutes):
                </Typography>
                <Slider
                    value={processingTime}
                    onChange={handleSliderChange}
                    aria-labelledby="processing-time-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={30}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProcessingTimeModal;
