import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Grid,
    Typography,
    Box,
    Paper,
    Button,
    Badge,
    Chip,
    Tabs,
    Tab
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFetchChartDetailsQuery, useFetchSlowChartDataQuery, useUpdateChartMutation } from '../apis/chart';
import CompletedCodesBuilder from './CodesBuilder/CompletedCodesBuilder';
import CoderQueries from './CoderQueries';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../styles/theme';
import { useFetchCoderQueriesQuery } from "../apis/physicians";
import HighlightedContent from "./HighlightDisplay";
import PDFViewer from "./ChartContent/PDFViewer";
import PatientInfoEditable from "./DetailComponents/EditablePatientDetails";

const ChartDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: chart, refetch: refetchDetail } = useFetchChartDetailsQuery(id);
    const { data: slowData } = useFetchSlowChartDataQuery(id);

    const { data: queries } = useFetchCoderQueriesQuery(id);

    const [updateChart] = useUpdateChartMutation();

    const [highlightedText, setHighlightedText] = useState(null);

    const [newMessageCount, setNewMessageCount] = useState(0);
    const [completedCodesKey, setCompletedCodesKey] = useState(Date.now());
    const [tabValue, setTabValue] = useState(0);
    const [contentTab, setContentTab] = useState(0);


    useEffect(() => {
        if (queries) {
            const unreadMessages = queries?.results?.filter(result => !result.is_read).length;
            setNewMessageCount(unreadMessages);
        }
    }, [queries]);

    useEffect(() => {
        setCompletedCodesKey(Date.now());
    }, [id]);

    const handleCodeClick = (supportingText) => {
        setHighlightedText(supportingText);
    };

    const navigateToChart = (chartId) => {
        navigate(`/chart/${chartId}`);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleContentTabChange = (event, newValue) => {
        setContentTab(newValue);
    };

    const handleDeficiencyToggle = async () => {
        try {
            await updateChart({ id: chart.uuid, documentation_deficiency: !chart.documentation_deficiency });
            refetchDetail();
        } catch (error) {
            console.error("Error updating chart documentation deficiency:", error);
        }
    };

    const handleBlockedToggle = async () => {
        try {
            await updateChart({ id: chart.uuid, blocked: !chart.blocked });
            refetchDetail();
        } catch (error) {
            console.error("Error updating chart blocked:", error);
        }
    };

    const statusColor = chart?.is_processed ? "success" : "error";

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ width: '100%', height: '100vh', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} alignItems="stretch" sx={{ flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                {chart && <PatientInfoEditable patient={chart.patient} />}

                                {/* Previous and Next Buttons */}
                                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>

                                        {slowData?.previous_chart && (
                                            <Button
                                                onClick={() => navigateToChart(slowData.previous_chart)}
                                                sx={{ padding: 2, marginRight: 5 }}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                <ArrowBackIosIcon sx={{ marginLeft: 1, color: 'white' }} />
                                                <Typography variant="body1" sx={{ marginLeft: 1, color: 'white' }}>Previous Chart</Typography>
                                            </Button>
                                        )}
                                        {slowData?.next_chart && (
                                            <Button
                                                onClick={() => navigateToChart(slowData.next_chart)}
                                                sx={{ padding: 2 }}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                <Typography variant="body1" sx={{ marginRight: 1, color: 'white' }}>Next Chart</Typography>
                                                <ArrowForwardIosIcon sx={{ marginLeft: 1, color: 'white' }} />
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Chart Details and Content */}
                    <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" gutterBottom>
                                    <strong>Chart Type:</strong> {chart?.chart_type || 'Unknown'}<br />
                                    <strong>DOS:</strong> {chart?.date_of_service || 'Unknown'}
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                                        <strong>Status:</strong>
                                    </Typography>
                                    <Chip
                                        label={chart?.is_processed ? 'Finalized' : 'Unprocessed'}
                                        color={statusColor}
                                        sx={{ fontSize: '1rem' }}
                                    />
                                </Box>
                            </Box>
                            <br />
                            <Button
                                variant="contained"
                                color={chart?.documentation_deficiency ? "error" : "primary"}
                                onClick={handleDeficiencyToggle}
                                sx={{ marginBottom: 2 }}
                            >
                                {chart?.documentation_deficiency ? "Unset Documentation Deficiency" : "Set as Documentation Deficient"}
                            </Button>
                            <Button
                                variant="contained"
                                color={chart?.blocked ? "error" : "primary"}
                                onClick={handleBlockedToggle}
                                sx={{ marginBottom: 2 }}
                            >
                                {chart?.blocked ? "Unset Blocked" : "Set as Blocked"}
                            </Button>
                            <Tabs value={contentTab} onChange={handleContentTabChange} aria-label="content tabs">
                                <Tab label="Body" />
                                <Tab label="Document" />
                            </Tabs>
                            {contentTab === 0 && chart && (
                                <Typography variant="h6" sx={{ flex: 1 }}>
                                    <HighlightedContent highlightedText={highlightedText} chart={chart} />
                                </Typography>
                            )}
                            {contentTab === 1 && slowData?.gcs_file_link && (
                                <Box sx={{ flex: 1, overflow: 'auto' }}>
                                    <PDFViewer
                                        fileUrl={slowData.gcs_file_link}
                                    />
                                </Box>
                            )}
                        </Paper>
                    </Grid>

                    {/* Code Builder and Coder Queries */}
                    <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="demo detail tabs">
                                <Tab label="Code Builder" />
                                <Tab label={
                                    <Badge badgeContent={newMessageCount} color="error">
                                        Coder Queries
                                    </Badge>
                                } />
                            </Tabs>
                            {tabValue === 0 && chart && (
                                <CompletedCodesBuilder
                                    key={completedCodesKey}
                                    chart={chart}
                                    onRefetch={refetchDetail}
                                    handleCodeClick={handleCodeClick}
                                />
                            )}
                            {tabValue === 1 && chart && (
                                <Box sx={{ padding: 2 }}>
                                    <CoderQueries chartId={chart.uuid} />
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default ChartDetails;
