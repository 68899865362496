import React, { useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { Box, Typography } from '@mui/material';

const HighlightedContent = ({ highlightedText, chart }) => {
    const containerRef = useRef(null);

    const convertNewlinesToBreaksAndHighlight = (text, highlightedText) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[highlightedText]}
                    autoEscape={true}
                    textToHighlight={line}
                />
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        if (containerRef.current && highlightedText) {
            // Query the highlighted element
            const highlightedElements = containerRef.current.querySelectorAll('.YourHighlightClass');
            if (highlightedElements.length > 0) {
                // Scroll to the first highlighted element
                highlightedElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [highlightedText, chart]);

    return (
        <Box ref={containerRef} sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
            <Typography variant="body1" component="div">
                {chart?.content ? convertNewlinesToBreaksAndHighlight(chart.content, highlightedText) : ''}
            </Typography>
        </Box>
    );
};

export default HighlightedContent;
