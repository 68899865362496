import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const promptApiSlice = createApi({
    reducerPath: 'promptApi',
    baseQuery,
    tagTypes: ['Prompt'],
    endpoints: (builder) => ({
        fetchPrompts: builder.query({
            query: ({ page = 1, pageSize = 10 }) => `prompts/?page=${page}&page_size=${pageSize}`,
            transformResponse: (response) => ({
                data: response.results,
                total: response.count,
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: 'Prompt', id })),
                          { type: 'Prompt', id: 'PARTIAL-LIST' },
                      ]
                    : [{ type: 'Prompt', id: 'PARTIAL-LIST' }],
        }),
        createPrompt: builder.mutation({
            query: (newPrompt) => ({
                url: 'prompts/',
                method: 'POST',
                body: newPrompt,
            }),
            invalidatesTags: [{ type: 'Prompt', id: 'PARTIAL-LIST' }],
        }),
        updatePromptContent: builder.mutation({
            query: ({ id, content }) => ({
                url: `prompts/${id}/update_content/`,
                method: 'POST',
                body: { content },
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Prompt', id }],
        }),
        deletePrompt: builder.mutation({
            query: (id) => ({
                url: `prompts/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Prompt', id }],
        }),
    }),
});

export const {
    useFetchPromptsQuery,
    useCreatePromptMutation,
    useUpdatePromptContentMutation,
    useDeletePromptMutation,
} = promptApiSlice;
