import React, { useState } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useUpdatePatientMutation } from '../../apis/patients';

const PatientInfoEditable = ({ patient }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [editedPatient, setEditedPatient] = useState({ ...patient });
    const [updatePatient] = useUpdatePatientMutation();

    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedPatient((prev) => ({ ...prev, [name]: value }));
    };

    const handlePatientInfoSave = () => {
        updatePatient(editedPatient);
        setIsEditable(false);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
                name="name"
                label="Name"
                value={editedPatient.name}
                onChange={handleChange}
                disabled={!isEditable}
                size="small"
            />
            <TextField
                name="dob"
                label="DOB"
                value={editedPatient.dob}
                onChange={handleChange}
                disabled={!isEditable}
                size="small"
            />
            <TextField
                name="gender"
                label="Gender"
                value={editedPatient.gender}
                onChange={handleChange}
                disabled={!isEditable}
                size="small"
            />
            <IconButton onClick={isEditable ? handlePatientInfoSave : handleEditToggle}>
                {isEditable ? <SaveIcon /> : <EditIcon />}
            </IconButton>
        </Box>
    );
};

export default PatientInfoEditable;
